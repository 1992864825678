/* stylelint-disable declaration-no-important, font-weight-notation, selector-no-qualifying-type, property-disallowed-list, scss/selector-no-redundant-nesting-selector */
.example:not(:first-child) {
  margin-top: 1.5rem;
}
.example .tab-content {
  background-color: #f9fafa !important;
}
.dark-theme .example .tab-content {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.example code[class*=language-],
.example pre[class*=language-] {
  font-size: 0.875rem !important;
}
.example :not(pre) > code[class*=language-],
.example pre[class*=language-] {
  background: transparent;
}
.example + p, .example + h2, .example + h3, .example + h4, .example + h5, .example + h6 {
  margin-top: 1.5rem;
}
.example .preview + p {
  margin-top: 2rem;
}
.example .preview > .form-control + .form-control {
  margin-top: 0.5rem;
}
.example .preview > .nav + .nav,
.example .preview > .alert + .alert,
.example .preview > .navbar + .navbar,
.example .preview > .progress + .progress {
  margin-top: 1rem;
}
.example .preview > .dropdown-menu {
  position: static;
  display: block;
}
.example .preview > :last-child {
  margin-bottom: 0;
}
.example .preview > svg + svg,
.example .preview > img + img {
  margin-left: 0.5rem;
}
.example .preview > .btn,
.example .preview > .btn-group {
  margin: 0.25rem 0.125rem;
}
.example .preview > .btn-toolbar + .btn-toolbar {
  margin-top: 0.5rem;
}
.example .preview > .list-group {
  max-width: 400px;
}
.example .preview > [class*=list-group-horizontal] {
  max-width: 100%;
}
.example .preview .fixed-top,
.example .preview .sticky-top {
  position: static;
  margin: -1rem -1rem 1rem;
}
.example .preview .fixed-bottom {
  position: static;
  margin: 1rem -1rem -1rem;
}
@media (min-width: 576px) {
  .example .preview .fixed-top,
.example .preview .sticky-top {
    margin: -1.5rem -1.5rem 1rem;
  }
  .example .preview .fixed-bottom {
    margin: 1rem -1.5rem -1.5rem;
  }
}
.example .preview .pagination {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.example .preview .docs-example-modal .modal {
  position: static;
  display: block;
}

.dark-theme {
  /* Code blocks */
  /* Inline code */
}
.dark-theme code[class*=language-],
.dark-theme pre[class*=language-] {
  font-family: Inconsolata, Monaco, Consolas, "Courier New", Courier, monospace;
  hyphens: none;
  line-height: 1.5;
  color: #c5c8c6;
  text-align: left;
  text-shadow: 0 1px rgba(0, 0, 0, 0.3);
  word-break: normal;
  word-spacing: normal;
  tab-size: 4;
  white-space: pre;
  direction: ltr;
}
.dark-theme pre[class*=language-] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
  border-radius: 0.3em;
}
.dark-theme :not(pre) > code[class*=language-],
.dark-theme pre[class*=language-] {
  background: transparent;
}
.dark-theme :not(pre) > code[class*=language-] {
  padding: 0.1em;
  border-radius: 0.3em;
}
.dark-theme .token.comment,
.dark-theme .token.prolog,
.dark-theme .token.doctype,
.dark-theme .token.cdata {
  color: #7c7c7c;
}
.dark-theme .token.punctuation {
  color: #c5c8c6;
}
.dark-theme .namespace {
  opacity: 0.7;
}
.dark-theme .token.property,
.dark-theme .token.keyword,
.dark-theme .token.tag {
  color: #96cbfe;
}
.dark-theme .token.class-name {
  color: #ffffb6;
  text-decoration: underline;
}
.dark-theme .token.boolean,
.dark-theme .token.constant {
  color: #9c9;
}
.dark-theme .token.symbol,
.dark-theme .token.deleted {
  color: #f92672;
}
.dark-theme .token.number {
  color: #ff73fd;
}
.dark-theme .token.selector,
.dark-theme .token.attr-name,
.dark-theme .token.string,
.dark-theme .token.char,
.dark-theme .token.builtin,
.dark-theme .token.inserted {
  color: #a8ff60;
}
.dark-theme .token.variable {
  color: #c6c5fe;
}
.dark-theme .token.operator {
  color: #ededed;
}
.dark-theme .token.entity {
  color: #ffffb6;
  cursor: help;
}
.dark-theme .token.url {
  color: #96cbfe;
}
.dark-theme .language-css .token.string,
.dark-theme .style .token.string {
  color: #87c38a;
}
.dark-theme .token.atrule,
.dark-theme .token.attr-value {
  color: #f9ee98;
}
.dark-theme .token.function {
  color: #dad085;
}
.dark-theme .token.regex {
  color: #e9c062;
}
.dark-theme .token.important {
  color: #fd971f;
}
.dark-theme .token.important,
.dark-theme .token.bold {
  font-weight: bold;
}
.dark-theme .token.italic {
  font-style: italic;
}

/*# sourceMappingURL=examples.css.map */
